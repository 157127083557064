import React, {ReactNode, useEffect} from "react";

// for styles
import { Backdrop } from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import {PlanSelectFormData, useCoupon} from "../../types/planSelectForm";
import {
  couponAlertModalParams,
  couponConditionsType,
  couponDepartureDate,
  couponDiscountSubjectType,
  couponPeriodType,
  couponReserveDate, couponValidates,
  useCouponResponse
} from "../../types/coupons";
import {getBasicTotalPrice, getCarRentalTotalPrice, getOptionTotalPrice, getTotalPrice} from "../../utils/price";
import dayjs from "dayjs";

import useModalHeightSwitch from "./ModalHeightSwitch";
import {useTranslation} from "react-i18next";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean;
  isShowClearCoupon: boolean;
  result: string;
  resultCoupon: useCouponResponse | undefined;
  callback: () => void;
  planSelectFormData: PlanSelectFormData;
};

const CouponModal: React.FC<Props> = ({ isShow, result, resultCoupon, callback, planSelectFormData, isShowClearCoupon }) => {

  const { t, i18n } = useTranslation();

  const [content, setContent] = React.useState<ReactNode>( );
  const [open, setOpen] = React.useState(false);
  const [conditions, setConditions] = React.useState<couponConditionsType | undefined>();

  useEffect(() => {
    if (resultCoupon) {
      const totalPrice = getTotalPrice(planSelectFormData);
      const basicTotal = getBasicTotalPrice( {
        isUpdate: false,
        step: 'total',
        updateHotelIndex: undefined,
        updatePlan: undefined,
        updateHotel: undefined,
        checkIn: planSelectFormData.searchParams.checkin,
        checkOut: planSelectFormData.searchParams.checkout
      }, planSelectFormData);
      const optionTotal = getOptionTotalPrice(planSelectFormData) + getCarRentalTotalPrice(planSelectFormData);
      const discount = resultCoupon?.body?.discount ?? 0;
      const couponItems: couponAlertModalParams = {
        total: totalPrice,
        basicTotal: basicTotal,
        optionTotal: optionTotal,
        discount: resultCoupon?.body?.discount ?? 0,
        discountPrice: totalPrice - discount,
        discountSubjectType: resultCoupon?.body?.discountSubjectType,
        periodType: resultCoupon?.body?.periodType,
        reservedDate: resultCoupon?.body?.reservedDate,
        departureDate: resultCoupon?.body?.departureDate,
        applyMinimumPrice: Number(resultCoupon?.body?.applyMinimumPrice ?? 0),
        conditions: resultCoupon?.body?.conditions ?? "none",
        validates: resultCoupon?.body?.validate,
      }
      setConditions(couponItems.conditions);

      switch (resultCoupon.status) {
        case 200:
          setContent(successContent(totalPrice, couponItems.discountPrice));
          break;
        case 400:
          // クーポンが終了している場合
          setContent(error400Content());
          break;
        case 401:
          // クーポンはあるが適用期間外だった場合
          setContent(error401Content(couponItems.periodType, couponItems.reservedDate, couponItems.departureDate));
          break;
        case 403:
          // クーポンはあるが適用条件の金額に届いていない場合
          setContent(error403Content(couponItems.discountSubjectType, couponItems.applyMinimumPrice, totalPrice, basicTotal, optionTotal));
          break;
        case 405:
          // クーポンはあるが金額、期間両方に該当しない場合
          if (couponItems.validates) {
            setContent(error405Content(couponItems));
          }
          break;
        case 406:
          setContent(error406Content(couponItems.conditions));
          break;
        case 407:
          setContent(error407Content());
          break;
        default:
          setContent(defaultContent());
          break;
      }
      setOpen(true);
    }
  }, [resultCoupon]);

  const defaultContent = () => {
    return (
      <div className="box-red mb-24">
        <p className="fz-13 fz-14-md c-red fw-b ta-c font-noto-sans-jp">
          {t("Coupon.入力されたクーポンコードは")}
          <br/>
          {t("Coupon.存在いたしません。")}
          <br/>
          {t("Coupon.提供されたクーポンコードをご確認ください。")}
        </p>
      </div>
    );
  }

  const successContent = (totalPrice: number, discountPrice: number) => {
    return (
      <div>
        {/* クーポンが適用された時 */}
        <p className="mb-20 fz-13 fz-14-md ta-c">
          {t("Coupon.以下のクーポンが適用されました。")}
        </p>
        <div className="box-light-gray mb-24">
          <p className="mb-12 c-navy fw-b ta-c fz-16 fz-18-md">
            {i18n.language === "tw" ? resultCoupon?.body?.couponNameTw : resultCoupon?.body?.couponName}
          </p>
          <div className="box-white d-f jc-sb">
              <span className="fz-13 fz-14-md fw-b bdr-r-gray pr-14 pt-4 pb-4">
                {t("Coupon.割引適用後")}
                <br/>
                {t("Coupon.旅行代金")}
              </span>
            <div>
            <span className="d-b fz-12 fz-14-md ta-r font-roboto">
              <s>￥{totalPrice.toLocaleString()}</s>
            </span>
              <span className="d-b fz-20 c-red fw-b ta-r font-roboto">
                ￥{discountPrice.toLocaleString()}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const error400Content = () => {
    return (
      <div className="box-red mb-24">
        <p className="fz-13 fz-14-md c-red fw-b ta-c font-noto-sans-jp">
          {t("Coupon.入力されたクーポンコードは")}
          <br/>
          {t("Coupon.終了しており適用できません。")}
        </p>
      </div>
    );
  }

  const error403Content = (discountSubjectType: couponDiscountSubjectType | undefined, applyMinimumPrice: number, total: number, basicTotal: number, optionTotal: number) => {
    return (
      <>
        {/* クーポンはあるが適用条件の金額に届いていない場合 */}
        <div className="box-red mb-16">
          <p className="fz-13 fz-14-md c-red fw-b ta-c font-noto-sans-jp">
            {t("Coupon.現在の旅行代金では")}
            <br />
            {t("Coupon.クーポン適応最低金額を満たしていないため、")}
            <br />
            {t("Coupon.クーポンが適用できません。")}
          </p>
        </div>
        <p className="mb-8 mb-8-md fz-14 fz-16-md fw-b ta-c">
          {t("Coupon.クーポン適用最低金額")}
        </p>
        <p className="pt-8 mb-8 bdr-t-gray">
          <span className="d-b fz-12 ta-c font-roboto">
            {/* 基本旅行代金か旅行代金合計かをPropsで渡された内容から判別して、テキストを変更する */}
            {discountSubjectType === "basic"
              ? t("Coupon.基本旅行代金が")
              : t("Coupon.追加オプション含む旅行代金合計が")}
          </span>
          <span className="d-b fz-24 fw-b ta-c font-roboto">
            ￥{applyMinimumPrice.toLocaleString()}〜
          </span>
        </p>
        <div className="table-rounded">
          <div className="table-rounded-heading">{t("Coupon.現在の旅行代金")}</div>
          <div className="table-rounded-content mb-24">
            <table>
              <tr>
                {/* 基本旅行代金か旅行代金合計かをPropsで渡された内容から判別して、文字色を赤くする */}
                <th
                  className={
                    discountSubjectType === "basic"
                      ? "color-red"
                      : ""
                  }
                >
                  {t("Coupon.基本旅行代金")}
                  <span>{t("Coupon.宿泊＋基本プラン")}</span>
                </th>
                <td
                  className={
                    discountSubjectType === "basic"
                      ? "color-red"
                      : ""
                  }
                >
                  ￥{basicTotal.toLocaleString()}
                </td>
              </tr>
              <tr>
                <th>
                  {t("Coupon.追加オプション料金")}
                  <span>{t("Coupon.追加オプション＋レンタカー")}</span>
                </th>
                <td>￥{optionTotal.toLocaleString()}</td>
              </tr>
              <tr>
                <th
                  className={
                    discountSubjectType === "all"
                      ? "color-red"
                      : ""
                  }
                >
                  合計
                </th>
                <td
                  className={
                    discountSubjectType === "all"
                      ? "color-red"
                      : ""
                  }
                >
                  ￥{total.toLocaleString()}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </>
    )
  }

  const error401Content = (periodType: couponPeriodType | undefined, reservedDate: couponReserveDate | undefined, departureDate: couponDepartureDate | undefined) => {
    return (
      <>
        {/* クーポンはあるが適用期間外だった場合 */}
        <div className="box-red mb-24">
          <p className="mb-12 mb-16-md fz-13 fz-14-md c-red fw-b ta-c font-noto-sans-jp">
            {t("Coupon.入力されたクーポンコードは、")}
            <br />
            {t("Coupon.以下の期間に限り適用されます。")}
            <br />
            {t("Coupon.期間をご確認ください。")}
          </p>
          <div className="box-white non-applicable">
            { (periodType === "reserve" || periodType === 'all' ) && (
              <div className={periodType === 'all' ? "d-f ai-c jc-c fd-c fd-r-md mb-12 mb-16-md" : "d-f ai-c jc-c fd-c fd-r-md"}>
              <span className="mb-4 mb-0-md fz-12 fz-13-md">
                {t("Coupon.受付日")}
              </span>
                <span className="ml-16-md fz-15 fw-b font-roboto">
                <span>{dayjs(reservedDate?.start).format('YYYY/MM/DD')}</span>〜<span>{dayjs(reservedDate?.end).format('YYYY/MM/DD')}</span>
              </span>
              </div>
            )}
            { (periodType === "departure" || periodType === 'all' ) && (
              <div className={periodType === 'all' ? "d-f ai-c jc-c fd-c fd-r-md pt-12 pt-16-md bdr-t-gray" : "d-f ai-c jc-c fd-c fd-r-md "}>
                <span className="mb-4 mb-0-md fz-12 fz-13-md">
                  {t("PlanSelectTop.出発日")}
                </span>
                <span className="ml-16-md fz-15 fw-b font-roboto">
                <span>{dayjs(departureDate?.start).format('YYYY/MM/DD')}</span>〜<span>{dayjs(departureDate?.end).format('YYYY/MM/DD')}</span>
              </span>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  const error405Content = (couponItems: couponAlertModalParams) => {
    if (couponItems.validates?.businessId){
      return (error407Content());
    }
    return (
      <>
        {/* クーポンはあるが適用条件外だった場合 */}
        {couponItems.validates?.conditions && error406Content(couponItems.conditions)}
        
        {/* クーポンはあるが適用期間外だった場合 */}
        {couponItems.validates?.dateRange && (
          <div className="box-red mb-24">
            <p className="mb-12 mb-16-md fz-13 fz-14-md c-red fw-b ta-c font-noto-sans-jp">
              {t("Coupon.入力されたクーポンコードは、")}
              <br/>
              {t("Coupon.以下の期間に限り適用されます。")}
              <br/>
              {t("Coupon.期間をご確認ください。")}
            </p>
            <div className="box-white non-applicable">
              {(couponItems.periodType === "reserve" || couponItems.periodType === 'all') && (
                <div
                  className={couponItems.periodType === 'all' ? "d-f ai-c jc-c fd-c fd-r-md mb-12 mb-16-md" : "d-f ai-c jc-c fd-c fd-r-md"}>
                  <span className="mb-4 mb-0-md fz-12 fz-13-md">
                    {t("Coupon.受付日")}
                  </span>
                  <span className="ml-16-md fz-15 fw-b font-roboto">
                <span>{dayjs(couponItems.reservedDate?.start).format('YYYY/MM/DD')}</span>〜<span>{dayjs(couponItems.reservedDate?.end).format('YYYY/MM/DD')}</span>
              </span>
                </div>
              )}
              {(couponItems.periodType === "departure" || couponItems.periodType === 'all') && (
                <div
                  className={couponItems.periodType === 'all' ? "d-f ai-c jc-c fd-c fd-r-md pt-12 pt-16-md bdr-t-gray" : "d-f ai-c jc-c fd-c fd-r-md "}>
                  <span className="mb-4 mb-0-md fz-12 fz-13-md">
                    {t("PlanSelectTop.出発日")}
                  </span>
                  <span className="ml-16-md fz-15 fw-b font-roboto">
                <span>{dayjs(couponItems.departureDate?.start).format('YYYY/MM/DD')}</span>〜<span>{dayjs(couponItems.departureDate?.end).format('YYYY/MM/DD')}</span>
              </span>
                </div>
              )}
            </div>
          </div>
        )}

        {/* クーポンはあるが適用条件の金額に届いていない場合 */}
        {couponItems.validates?.minimumPrice && (
          <>
            <div className="box-red mb-16">
              <p className="fz-13 fz-14-md c-red fw-b ta-c font-noto-sans-jp">
                {t("Coupon.現在の旅行代金では")}
                <br/>
                {t("Coupon.クーポン適応最低金額を満たしていないため、")}
                <br/>
                {t("Coupon.クーポンが適用できません。")}
              </p>
            </div>
            <p className="mb-8 mb-8-md fz-14 fz-16-md fw-b ta-c">
              {t("Coupon.クーポン適用最低金額")}
            </p>
            <p className="pt-8 mb-8 bdr-t-gray">
              <span className="d-b fz-12 ta-c font-roboto">
            {/* 基本旅行代金か旅行代金合計かをPropsで渡された内容から判別して、テキストを変更する */}
            {couponItems.discountSubjectType === "basic"
              ? t("Coupon.基本旅行代金が")
              : t("Coupon.追加オプション含む旅行代金合計が")}
          </span>
              <span className="d-b fz-24 fw-b ta-c font-roboto">
            ￥{couponItems.applyMinimumPrice.toLocaleString()}〜
          </span>
            </p>
            <div className="table-rounded">
              <div className="table-rounded-heading">
                {t("Coupon.現在の旅行代金")}
              </div>
              <div className="table-rounded-content mb-24">
                <table>
                  <tr>
                    {/* 基本旅行代金か旅行代金合計かをPropsで渡された内容から判別して、文字色を赤くする */}
                    <th
                      className={
                        couponItems.discountSubjectType === "basic"
                          ? "color-red"
                          : ""
                      }
                    >
                      {t("Coupon.基本旅行代金")}
                      <span>{t("Coupon.宿泊＋基本プラン")}</span>
                    </th>
                    <td
                      className={
                        couponItems.discountSubjectType === "basic"
                          ? "color-red"
                          : ""
                      }
                    >
                      ￥{couponItems.total.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {t("Coupon.追加オプション料金")}
                      <span>{t("Coupon.追加オプション＋レンタカー")}</span>
                    </th>
                    <td>￥{couponItems.optionTotal.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th
                      className={
                        couponItems.discountSubjectType === "all"
                          ? "color-red"
                          : ""
                      }
                    >
                      {t("ReservationCancelFee.合計")}
                    </th>
                    <td
                      className={
                        couponItems.discountSubjectType === "all"
                          ? "color-red"
                          : ""
                      }
                    >
                      ￥{couponItems.total.toLocaleString()}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  const error406Content = (conditions: couponConditionsType) => {
    if (conditions === "none") {
      return;
    }
    return (
      <div className="box-red mb-24">
        <p className="fz-13 fz-14-md c-red fw-b ta-c font-noto-sans-jp">
          {conditions === 'rentalCar' && (
            <>
              {t("Coupon.このクーポンはレンタカー利用時のみ適用されます。")}
              <br/>
              {t("Coupon.レンタカーの利用をご検討ください。")}
            </>
          )}
          {conditions === 'option' && (
            <>
              {t("Coupon.このクーポンは追加オプション利用時のみ適用されます。")}
              <br/>
              {t("Coupon.追加オプションの利用をご検討ください。")}
            </>
          )}
        </p>
      </div>
    );
  }

  const error407Content = () => {
    return (
      <div className="box-red mb-24">
        <p className="fz-13 fz-14-md c-red fw-b ta-c font-noto-sans-jp">
          {t("Coupon.このクーポンコードはこの商品には適用できません。")}
          <br/>
          {t("Coupon.対象商品をご確認ください。")}
        </p>
      </div>
    );
  }

  const clearCouponContent = (conditions: couponConditionsType) => {
    return (
      <>
        <div className="box-red mb-24">
          <p className="fz-13 fz-14-md c-red fw-b ta-c font-noto-sans-jp">
            {conditions === 'rentalCar' && (
              <>
                {t("Coupon.適用されていたクーポンが取り消されました。")}
                <br/>
                {t("Coupon.このクーポンはレンタカー利用時のみ適用されます。")}
                <br/>
                {t("Coupon.レンタカーの利用をご検討ください。")}
              </>
            )}
            {conditions === 'option' && (
              <>
                {t("Coupon.適用されていたクーポンが取り消されました。")}
                <br/>
                {t("Coupon.このクーポンは追加オプション利用時のみ適用されます。")}
                <br/>
                {t("Coupon.追加オプションの利用をご検討ください。")}
              </>
            )}
          </p>
        </div>
      </>
    );
  }

  useEffect(() => {
    if (isShowClearCoupon && conditions) {
      setContent(clearCouponContent(conditions));
      setOpen(true);
    }

  }, [isShowClearCoupon]);

  const handleClose = () => {
    callback();
    setOpen(false)
  }

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          className="modal-coupon"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 300,
          }}
        >
          <div className="modal-coupon-inner js-modal-scroll">
            <div className="modal-coupon-close-btn">
              <button className="button-clear" onClick={handleClose}>
                <i className="icon-modal-close-navy fz-28"></i>
              </button>
            </div>
            <div className="modal-coupon-content">
              <div className="pr-10 pr-14-md h-100per ov-a">
                <div className="js-modal-height">
                  <p
                    className={`fw-b fz-16 fz-18-md ta-c c-navy ${
                      result.startsWith("non-") ? "mb-20" : "mb-8"
                    }`}
                  >
                    {t("Coupon.クーポン割引の適用")}
                  </p>

                  {/* クーポン適用されない場合 */}
                  {content}

                  <div className="d-f jc-c">
                    <button
                      className="modal-coupon-close-text button-clear"
                      onClick={handleClose}
                    >
                      {t("Common.閉じる")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      { useModalHeightSwitch(open) }
    </>
  );
};

export default CouponModal;
