import React, { useEffect, Fragment } from "react";
import _ from "lodash";

// for styles
import {
    Button,
    Backdrop
} from "@material-ui/core/";

// toggle button
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// list
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

//モーダルをインポート
import Modal from "@material-ui/core/Modal";
import {
  HotelHandModal,
  PlanDetailModal,
  RoomDetailModal
} from "./"
import {defaultHotelDetail} from "../../defaults/hotel";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {hotelList, hotelPlanDetail, roomData} from "../../types/Hotel";
import {
  diffUpdateHotelPrice, filterRooms,
  getHotelTypeLabel,
  getRoomTypeLabel,
  hotelRoomSearch,
  hotelRoomsSearch,
  hotelsAllSearch,
  hotelsDetailSearch,
  hotelSearch,
  mergeHotelRoomPlans,
  selectHotelIndex,
  getHotelAreaCode,
  getRoomTypeCodeConfigByLanguageCode
} from "../../utils/hotel";
import SearchLoading from "../loading/searchLoading";
import {dateFormat, dayAgo, nextDate, rageDiffDate, rangeDate, toCircled} from "../../utils/convert";
import {SearchNotFound} from "../index";
import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// import {roomTypeCode} from "../../config/hotel";
import PartsHotelTagList from "../parts/PartsHotelTagList";
import DatePicker, { registerLocale } from "react-datepicker";
import dayjs from "dayjs";
import {devLog} from "../../utils/errors";
import ja from 'date-fns/locale/ja';
import zhTW from 'date-fns/locale/zh-TW';
import { useTranslation } from "react-i18next";
import { DATEPICKER_LOCALES, LANGUAGES } from "../../constants/languages";
import {getHotelSettings} from "../../utils/sellerProduct";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  isUpdate: boolean,
  updateHotelIndex: number|undefined,
  callback: () => void,
  defaultStep: number,
  selectHotelCode: string,
  date: string,
  planSelectFormData: PlanSelectFormData,
  stepHandler: ( step: number ) => void,
  updateSelectHotel(planSelectFormData: PlanSelectFormData): void,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const StaySelectModal: React.FC<Props> = ({
  isShow,
  isUpdate,
  updateHotelIndex,
  callback,
  selectHotelCode,
  defaultStep,
  date,
  planSelectFormData,
  stepHandler,
  updateSelectHotel,
}) => {

  const classesForLoading = useStyles();
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [hotels, setHotels] = React.useState<hotelList[]>([]);
  const [hotelCode, setHotelCode] = React.useState<string>(selectHotelCode);
  const [hotelList, setHotelList] = React.useState<hotelList>(defaultHotelDetail);
  const [hotelDetail, setHotelDetail] = React.useState<hotelList>();
  const [hotelRooms, setHotelRooms] = React.useState<roomData[]>([]);
  const [roomData, setRoomData] = React.useState<roomData|undefined>(undefined);
  const [planData, setPlanData] = React.useState<hotelPlanDetail|undefined>(undefined);
  const [checkIn, setCheckIn] = React.useState<string>("");
  const [checkOut, setCheckOut] = React.useState<string>("");
  const [isDateError, setIsDateError] = React.useState(false);
  const [searchTotal, setSearchTotal] = React.useState(0);
  const searchParams = planSelectFormData.searchParams;
  const minDate = planSelectFormData.searchParams.start;
  const maxDate = planSelectFormData.searchParams.end;
  const [planExists, setPlanExists] = React.useState(false); // プランが1つでも存在するか

  const { t, i18n } = useTranslation();
  
  // ホテルの絞り込み条件
  const [areaSelectOption, setAreaSelectOption] = React.useState<string[]>();
  const [defaultHotels, setDefaultHotels] = React.useState<hotelList[]>([]);
  // ホテルの並び替え条件
  const [selectedHotelSort, setSelectedHotelSort] = React.useState("recommend");
  // 宿泊プランの並び替え条件
  const [selectedRoomSort, setSelectedRoomSort] = React.useState("");

  // 絞り込み条件(ルームタイプ)
  const [roomTypeFilter, setRoomTypeFilter] = React.useState<{label: string, value: string}[]>([]);
  const [mealFilter, setMealFilter] = React.useState<{label: string, value: string}[]>([]);
  
  const [roomTypeFilterState, setRoomTypeFilterState] = React.useState<string[]>([]);
  const handleRoomTypeFilter = (
    event: React.MouseEvent<HTMLElement>,
    newRoomDetailToggle: string[],
  ) => {
    setRoomTypeFilterState(newRoomDetailToggle);
  };

  const [mealFilterState, setMealFilterState] = React.useState<string[]>([]);
  const handleMealFilter = (
    event: React.MouseEvent<HTMLElement>,
    newRoomDetailToggle: string[],
  ) => {
    setMealFilterState(newRoomDetailToggle);
  };

  /**
   * 宿泊施設の並び替え
   * @param sortKey 
   * @param sortType 
   */
  const sortHotels = (sortKey: string, sortType: string) => {
    setSelectedHotelSort(sortType);
    let newHotels = _.cloneDeep(hotels);
    if (newHotels) {
      if (sortType === "recommend" && defaultHotels) {
        setHotels(defaultHotels);
      } else {
        // @ts-ignore
        newHotels = _.sortBy(newHotels, sortKey);
        if (sortType === "desc") {
            newHotels.reverse();
        }
        setHotels(newHotels);
      }
    }
  }

  /**
   * 宿泊プランの並び替え
   * @param sortType 
   */
  const sortRoomPlan = (sortType: string) => {
    setSelectedRoomSort(sortType);
    let newHotelDetail = _.cloneDeep(hotelDetail);
    if (newHotelDetail?.roomData && newHotelDetail.roomData.length > 0) {
      newHotelDetail.roomData = _.sortBy(newHotelDetail.roomData, "first_plan.total_price");
      if (sortType === "desc") {
        newHotelDetail.roomData.reverse();
      }
      setHotelDetail(newHotelDetail);
    }
  }

  // カレンダー用
  registerLocale("ja", ja);
  registerLocale("zhTW", zhTW);

  useEffect(() => {
    if (defaultStep === 1) {
      // 並び替えの選択を解除
      setSelectedIndex(0);
      setAreaSelectOption([]);
      // ホテルの絞り込み条件を設定
      setSelectedHotelSort("recommend");
      if (defaultHotels) {
        setHotels(defaultHotels);
      }
    } else if (defaultStep === 2) {
      // 宿泊プランと並び替えの条件を初期化
      setMealFilter([]);
      setRoomTypeFilter([]);
      setRoomTypeFilterState([]); // 部屋タイプ
      setMealFilterState([]); // 食事
    }

    if (!isShow) {
      setIsDateError(false);
      setCheckIn("");
      setCheckOut("");
    }
    
    actionHotelSearch(undefined, undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isShow, hotelCode])
  
  useEffect(() => {
    if (defaultStep === 1 && hotelDetail) {
      actionHotelSearch(checkIn, checkOut);
    } else if(defaultStep === 2 && hotelCode) {
      actionHotelSearch(checkIn, checkOut);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultStep])

  const setDate = (checkInDate: string | undefined, checkOutDate: string | undefined) => {
    const checkDate = {
      checkIn: "",
      checkOut: ""
    }

    if ( checkInDate !== undefined && checkInDate !== "" && checkOutDate !== undefined && checkOutDate !== ""){
      checkDate.checkIn = checkInDate;
      checkDate.checkOut = checkOutDate;
      setCheckIn(checkInDate);
      setCheckOut(checkOutDate);
    } else if (isUpdate)  {
      const updateHotelIndex = selectHotelIndex(planSelectFormData.hotel, date);
      const updateHotel = planSelectFormData.hotel[updateHotelIndex];
      const updateCheckIn = updateHotel?.checkIn ?? date;
      const updateCheckOut = updateHotel?.checkOut ?? nextDate(date, 1, 'YYYY-MM-DD');

      checkDate.checkIn = updateCheckIn;
      checkDate.checkOut = updateCheckOut;
      setCheckIn(updateCheckIn);
      setCheckOut(updateCheckOut);
    } else {
      const updateCheckIn = date;
      const updateCheckOut = nextDate(date, 1, 'YYYY-MM-DD');
      checkDate.checkIn = updateCheckIn;
      checkDate.checkOut = updateCheckOut;
      setCheckIn(updateCheckIn);
      setCheckOut(updateCheckOut);
    }

    return checkDate;
  }

  const updateFilterOptions = (hotelList: hotelList[]) => {
    let selectAreaList: string[] = [t("StaySearchbox.すべて")]; // エリア絞り込みの項目
    // @ts-ignore
    let resultHotels = hotelList.map((item, index) => {
      selectAreaList.push(item.sub_area_code ?? ""); // エリアを追加
      let result: any = item;
      // @ts-ignore
      result["sortIndex"] = index; // 並び替え用
      return result;
    })
    selectAreaList = _.uniq(selectAreaList); // 重複した項目をまとめる
    setAreaSelectOption(selectAreaList); // エリの絞り込みの項目を更新

    // ホテルの並び順を設定
    const hotelSetting = getHotelSettings(planSelectFormData);
    const target = hotelSetting?.target ?? "";

    // NOTE: APIから返ってくる(=Firestoreに保存されている)のが日本語なのでそのままでOK
    if (target === "ホテル指定") {
      const code = planSelectFormData?.planData?.sellerIndo?.hotelSettings?.hotelCode ?? "";
      const hotelCodes = code.split(',');
      if (hotelCodes.length > 0) {
        const sortHotelList: any = [];
        hotelCodes.forEach((hotelCode: string) => {
            const targetHotel = _.find(resultHotels, ["hotel_code", hotelCode]);
            if (targetHotel) {
              sortHotelList.push(targetHotel);
            }
        })
        resultHotels = sortHotelList;
      }
    }

    setHotels([...resultHotels]); // ホテル一覧を更新
    setDefaultHotels([...resultHotels]);
  }
  
  const actionHotelSearch = (checkInDate: string | undefined, checkOutDate: string | undefined) => {
    setLoading(true)
    setSearchTotal(0);
    const checkDate = setDate(checkInDate, checkOutDate);
    const updateCheckIn = checkDate.checkIn;
    const updateCheckOut = checkDate.checkOut;
    const hotelAreaCode = getHotelAreaCode(planSelectFormData);
    if (updateCheckIn !== "" && updateCheckOut !== "" && isShow) {
      // ホテル、プラン変更
      (async () => {
        const params = planSelectFormData.searchParams;
        params.checkin = updateCheckIn;
        params.checkout = updateCheckOut;
        if (defaultStep === 1) {
          const target = planSelectFormData.planData?.sellerIndo.hotelSettings.target;
          const code = planSelectFormData.planData?.sellerIndo.hotelSettings.hotelCode;
          let resultHotels = await hotelsAllSearch(hotelAreaCode, target, code, params, i18n.language);

          setSearchTotal(resultHotels.length);
          updateFilterOptions(resultHotels); // 絞り込み条件と並び替え用のインデックス追加

        } else if (defaultStep === 2 && hotelCode !== "") {
          const params = planSelectFormData.searchParams;
          params.checkin = updateCheckIn;
          params.checkout = updateCheckOut;

          // NOTE: 条件にしか使っていないので日本語でOK
          const resultHotel = await hotelSearch(hotelAreaCode, "ホテル指定", hotelCode, searchParams, 1, i18n.language);

          const hotelSearchFilter = resultHotel.filter((item) => item.hotel_code === hotelCode)[0];
          if (hotelSearchFilter !== undefined) {
            const cHotel = hotelSearchFilter;

            if(cHotel !== undefined && cHotel.hotelData === undefined && planSelectFormData !== undefined) {
              const resultHotelDetail = await hotelsDetailSearch(hotelAreaCode, searchParams, hotelCode, i18n.language);
              if (resultHotelDetail) {
                cHotel.hotelData = resultHotelDetail;
              }
            }

            if(cHotel !== undefined && cHotel.roomData === undefined && planSelectFormData !== undefined) {
              const resultHotelRooms = await hotelRoomsSearch(hotelAreaCode, searchParams, cHotel.hotel_code, i18n.language);
              if(resultHotelRooms) {
                setHotelRooms(resultHotelRooms);
                cHotel.roomData = mergeHotelRoomPlans( cHotel.hotelData, resultHotelRooms);
              }
            }
            
            const planIsExistsRooms = _.filter(cHotel?.roomData, (room) => room.planList.length > 0)
            if (cHotel !== undefined && planIsExistsRooms.length > 0) {
              setPlanExists(true);
              let roomTypeList: {label: string, value: string}[] = []; // 選択可能なルームタイプ
              let mealList: {label: string, value: string}[] = []; // 選択可能な食事
              const accordionStateList: boolean[] = [];
              const roomTypeCode = getRoomTypeCodeConfigByLanguageCode(i18n.language);

              const mealLabelBreakfast = t("Hotel.朝食付き");
              const mealLabelLunch = t("Hotel.昼食付き");
              const mealLabelDinner = t("Hotel.夕食付き");
              const mealLabelNothing = t("Hotel.食事なし");

              if (cHotel?.hotelData?.room_list) {
                cHotel.hotelData.room_list.forEach((roomData) => {
                  const roomType = roomTypeCode[roomData.room_type_code];
                  roomTypeList.push({label: roomType, value: roomData.room_type_code});

                  // フィルタ部分で使用される
                  // 食事の有無("1"=あり、"0"=なし)
                  roomData.plan_list.forEach((plan: any) => {
                    if (plan.meal_breakfast === "1") {
                      // mealList.push({label: "朝食付き", value: "meal_breakfast"});
                      // mealList.push({label: "附早餐", value: "meal_breakfast"});
                      mealList.push({label: mealLabelBreakfast, value: "meal_breakfast"});
                    }
                    if (plan.meal_lunch === "1") {
                      // mealList.push({label: "昼食付き", value: "meal_lunch"});
                      // mealList.push({label: "附午餐", value: "meal_lunch"});
                      mealList.push({label: mealLabelLunch, value: "meal_lunch"});
                    }
                    if (plan.meal_dinner === "1") {
                      // mealList.push({label: "夕食付き", value: "meal_dinner"});
                      // mealList.push({label: "附晚餐", value: "meal_dinner"});
                      mealList.push({label: mealLabelDinner, value: "meal_dinner"});
                    }
                    if (plan.meal_breakfast === "0" && plan.meal_lunch === "0" && plan.meal_dinner === "0") {
                      // mealList.push({label: "食事なし", value: "meal_nothing"});
                      // mealList.push({label: "無附餐", value: "meal_nothing"});
                      mealList.push({label: mealLabelNothing, value: "meal_nothing"});
                    }
                  })
                  accordionStateList.push(false);
                  return roomData;
                })

              }
              roomTypeList = _.uniqBy(roomTypeList, "label");
              mealList = _.uniqBy(mealList, "label");
              setMealFilter(mealList);
              setRoomTypeFilter(roomTypeList);
              setHotelDetail({...cHotel});
              SetStayAccordionStateList(accordionStateList); // アコーディオンの状態
            } else {
              setPlanExists(false);
              setMealFilter([]); // 絞り込み条件(食事)
              setRoomTypeFilter([]); // 絞り込み条件(部屋タイプ)
              SetStayAccordionStateList([]); // アコーディオンの状態
            }
          }
        }

        setLoading(false);
      } )()

    }
  }
  
  const changeCheckIn = (newValue: Date | null) => {
    const _date = dayjs(newValue).format('YYYY-MM-DD');
    if (checkOut && rageDiffDate(_date, checkOut) <= 0) {
      // alert("チェックアウト日より前の日を選択してください");
      // alert("請選擇早於退房日的日期。");
      alert(t("StaySelectModal.チェックアウト日より前の日を選択してください"));
    } else if (checkDateRange(_date, "in", updateHotelIndex)) {
      setIsDateError(true);
      // alert("指定したチェックイン日は選択できません。");
      // alert("無法選擇您所指定的入住日期。");
      alert(t("StaySelectModal.指定したチェックイン日は選択できません。"));
    } else {
      setIsDateError(false);
      actionHotelSearch(_date, checkOut);
    }
  }
  
  const changeCheckOut = (newValue: Date | null) => {
    const _date = dayjs(newValue).format('YYYY-MM-DD');
    if (checkIn && rageDiffDate(checkIn, _date) <= 0) {
      // alert("チェックイン日より後の日を選択してください");
      // alert("請選擇後於入住日的日期。");
      alert(t("StaySelectModal.チェックイン日より後の日を選択してください"));
    } else if (checkDateRange(_date, "out", updateHotelIndex)) {
      setIsDateError(true);
      // alert("指定したチェックアウト日は選択できません。");
      // alert("無法選擇您所指定的退房日期");
      alert(t("StaySelectModal.指定したチェックアウト日は選択できません。"));
    } else {
      setIsDateError(false);
      actionHotelSearch(checkIn, _date);
    }
  }
  
  const checkDateRange = (date: string, checkType: string, updateIndex: number | undefined) => {
    let dateRange:string[] = [];
    let setDateRange:string[] = [];
    planSelectFormData.hotel.forEach((hotel, index) => {
      let range: string[] = [];
      const checkInDate = hotel.checkIn;
      const checkOutDate = hotel.checkOut;
      if (updateIndex === undefined || updateIndex !== index) {
        if(checkType === "out") {
          range = rangeDate(nextDate(checkInDate, 1, "YYYY-MM-DD"), checkOutDate);
        } else if(checkType === "in") {
          range = rangeDate(checkInDate, dayAgo(checkOutDate, 1, "YYYY-MM-DD"));
        }
        if (range.length > 0) {
          dateRange = dateRange.concat(range);
        }
      }
    })

    if (dateRange.length > 0) {
      if (checkType === 'in') {
        setDateRange = rangeDate(date, dayAgo(checkOut, 1, "YYYY-MM-DD"));
      } else if (checkType === 'out'){
        setDateRange = rangeDate(nextDate(checkIn, 1, "YYYY-MM-DD"), date);
      }
      if (setDateRange.length > 0) {
        devLog("setDateRange", setDateRange);
        devLog("dateRange", dateRange);
        return setDateRange.filter((setDate) => dateRange.includes(setDate) ).length > 0;
      }
    }

    return false;
  }
  
  // // モーダルのステータスを追加する
  const [a, setA] = React.useState(false);

  // モーダルのhandleを追加する
  const handleHotelDetail = (hotelData: hotelList) => {
    setHotelList({...hotelData})
    setA(!a)
  }
  const handleA = () => {
    setA(!a)
  }

  const onPageHandle = ( step:number ) => {
    stepHandler(step)
  }

  const onPageHandlePlanDetail = ( code:string ) => {
    if (code) {
      // 選択済みの絞り込み条件を選択解除する
      setRoomTypeFilterState([]); // 部屋タイプ
      setMealFilterState([]); // 食事
      setMealFilter([]);
      setRoomTypeFilter([]);
      
      setHotelCode(code)
      stepHandler(2)
    }
  }

  const onPageModalHandlePlanDetail = ( code:string ) => {
    if (code) {
      setHotelCode(code)
      setA(!a)
      stepHandler(2)
    }
  }

  //-------------ここまでモーダルの処理------------------

  //検索のトグルボタン
  // const [RoomDetailToggleState, setRoomDetailToggleState] = React.useState(() => ['all']);
    // const handleRoomDetailToggle = (
    //     event: React.MouseEvent<HTMLElement>,
    //     newRoomDetailToggle: string[],
    // ) => {
    //     setRoomDetailToggleState(newRoomDetailToggle);
    // };

  //   const toggleCreateData = (
  //     label: string,
  //     value: string,
  // ) => {
  //     return { label, value };
  // };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const StaySelectOpen = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

  const handleMenuItemClick = (
      event: React.MouseEvent<HTMLElement>,
      index: number,
    ) => {
      // hotelsのデータを絞り込む
      const areaCode = areaSelectOption ? areaSelectOption[index] : "";
      const newHotels = defaultHotels.filter((hotel) => {
        // if (event.currentTarget.textContent === "すべて") {
        // if (event.currentTarget.textContent === "全部") {
        if (event.currentTarget.textContent === t("StaySearchbox.すべて")) {
          return true;
        }
        return hotel.sub_area_code === areaCode;
      });
      setSearchTotal(newHotels.length);
      setHotels(newHotels);
      setSelectedIndex(index);
      setAnchorEl(null);
    };

  const StaySlectClose = () => {
      setAnchorEl(null);
    };

  // const toggleCreateData2 = (
  //     label: string,
  //     value: string,
  // ) => {
  //     return { label, value };
  // };

  //アコーディオン：制御
  const[StayAccordionStateList, SetStayAccordionStateList] = React.useState<boolean[]>([]);
  const handleStayAccordionList = (index: number) => {
    if (StayAccordionStateList[index] !== undefined) {
      const updateStatusList = _.cloneDeep(StayAccordionStateList);
      updateStatusList[index] = !updateStatusList[index];
      SetStayAccordionStateList(updateStatusList);
    }
  }

  /**
   * ホテル追加・更新
   * @param planData
   * @param room
   */
  const callbackUpdateSelectHotel = (planData: hotelPlanDetail|undefined, room:roomData) => {
    if (planSelectFormData !== undefined && hotelDetail !== undefined) {
      const hotelAreaCode = getHotelAreaCode(planSelectFormData);
      (async () => {
        setUpdateLoading(true);
        if (isUpdate && updateHotelIndex !== undefined && planSelectFormData.hotel[updateHotelIndex] !== undefined) {
          // ルーム詳細取得
          const resultRoom = await hotelRoomSearch(hotelAreaCode, planSelectFormData.searchParams, hotelCode, room.detail.room_info.room_code, planData?.plan_code, i18n.language);
          if (resultRoom && resultRoom.success) {
            planSelectFormData.hotel[updateHotelIndex].detail = hotelDetail;
            planSelectFormData.hotel[updateHotelIndex].plan = planData;
            planSelectFormData.hotel[updateHotelIndex].roomData = room;
            planSelectFormData.hotel[updateHotelIndex].checkIn = checkIn;
            planSelectFormData.hotel[updateHotelIndex].checkOut = checkOut;
            planSelectFormData.hotel[updateHotelIndex].useDate = checkIn;
            planSelectFormData.hotel[updateHotelIndex].area = hotelDetail.sub_area_code?? "";
            planSelectFormData.hotel[updateHotelIndex].checkTime = hotelDetail.hotelData?.hotel_info.check_in_start;
            planSelectFormData.hotel[updateHotelIndex].total = planData?.room_plan_detail.total_price?? 0;
            planSelectFormData.hotel[updateHotelIndex].planRoomCode = resultRoom.response.room_plan_detail.room_plan_code;
          } else {
            // alert("指定したホテルが選択できませんでした。");
            // alert("無法選擇您所指定的酒店。");
            alert(t("StaySelectModal.指定したホテルが選択できませんでした。"));
          }
        } else {
          // ルーム詳細取得
          const resultRoom = await hotelRoomSearch(hotelAreaCode, planSelectFormData.searchParams, hotelCode, room.detail.room_info.room_code, planData?.plan_code, i18n.language);
          if (resultRoom && resultRoom.success) {
            planSelectFormData.hotel.push({
              detail: hotelDetail,
              plan: planData,
              roomData: room,
              checkIn: checkIn,
              checkOut: checkOut,
              useDate: checkIn,
              area: hotelDetail.sub_area_code ?? "",
              checkTime: hotelDetail.hotelData?.hotel_info.check_in_start,
              total: planData?.room_plan_detail.total_price ?? 0,
              planRoomCode: resultRoom.response.room_plan_detail.room_plan_code
            });
          } else {
            // alert("指定したホテルが選択できませんでした。");
            // alert("無法選擇您所指定的酒店。");
            alert(t("StaySelectModal.指定したホテルが選択できませんでした。"));
          }
        }
        setUpdateLoading(false);
        updateSelectHotel({...planSelectFormData});
        callback();
        if (PlanDetailModalState) {
          setPlanDetailModalState(!PlanDetailModalState);
        }
      })();
    }
  }

  /**
   * 選択したホテルコードを設定
   */
  useEffect(() => {
    setHotelCode(selectHotelCode);
  }, [selectHotelCode])


  //モーダル：プラン詳細詳細
  const [PlanDetailModalState, setPlanDetailModalState] = React.useState(false);

  // モーダル：客室詳細
  const [roomDetailModalState, setRoomDetailModalState] = React.useState(false);
  const handleRoomDetailModal = () => {
    setRoomDetailModalState(!roomDetailModalState);
  };
  
  const openRoomDetailModal = (room: roomData) => {
    setRoomData({...room});
    setRoomDetailModalState(!roomDetailModalState);
  }
  
  const onClickPlandetailModal = (planDetail: hotelPlanDetail|undefined, roomDetail: roomData) => {
    setRoomData({...roomDetail});
    if (planDetail !== undefined) {
      setPlanData({...planDetail})
    }
    setPlanDetailModalState(!PlanDetailModalState);
  };

  const handlPlandetailModal = () => {
    setPlanDetailModalState(!PlanDetailModalState);
  };

  const roomBaxClass = (index:number) => {
    if (index > 0) {
      return "box-white radius-none small-pc mt-40";
    }
    return "box-white radius-none small-pc "
  };

  /**
   * 絞り込み
   */
  useEffect(() => {
    // todo 部屋データを絞り込む
    if (hotelDetail) {
      hotelDetail.roomData = hotelRooms;
      const updateHotelData = filterRooms(hotelDetail, {
        roomTypes: roomTypeFilterState,
        mealTypes: mealFilterState,
      });
      if (updateHotelData) {
        setHotelDetail({...updateHotelData});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomTypeFilterState, mealFilterState]);

  //readOnly属性を付与するとdate-pickerが表示されなくなるので対策
  const ForceReadOnlyInput = React.forwardRef((props, ref) => (
    <input {...props} readOnly={true} />
  ));

  return (
    <>
    <Modal
      open={isShow}
      onClose={callback}
      className="modal"
      BackdropComponent={Backdrop}
      BackdropProps={{
          timeout: 300,
      }}
    >
      <div>
        <Backdrop className={classesForLoading.backdrop} open={updateLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="modal-fixed light-gray">
          <div className="modal-fixed-heading">
            {/* <p className="modal-fixed-heading-text">選擇住宿</p> */}
            <p className="modal-fixed-heading-text">{t("StaySelectModal.宿泊の選択")}</p>
            <p className="modal-fixed-close">
              <Button onClick={callback} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>{t("Common.閉じる")}
              </Button>
            </p>
          </div>
          <div className="modal-fixed-content pb-small">
            <div className="modal-fixed-content-inner small">
              {/* <h2 className="heading-2 mt-0">ご希望の宿泊プランをお選びください</h2> */}
              {/* <h2 className="heading-2 mt-0">請選擇您所希望的住宿方案</h2> */}
              <h2 className="heading-2 mt-0">{t("StaySelectModal.ご希望の宿泊プランをお選びください")}</h2>  {/* 両方こっち */}
              <div>
                <div className="card medium mb-20">
                  <div className="serchbox-stay mb-20">
                    {/* <p className="fz-16 fz-15-md mb-16 mb-0-md fw-b ta-c pr-20-md w-180-md">宿泊条件</p> */}
                    {/* <p className="fz-16 fz-15-md mb-16 mb-0-md fw-b ta-c pr-20-md w-180-md">入住條件</p> */}
                    <p className="fz-16 fz-15-md mb-16 mb-0-md fw-b ta-c pr-20-md w-180-md">{t("StaySelectModal.宿泊条件")}</p>
                    <div className="d-f-md fw-w bl-gray-md pl-30-md">
                      <div className="w-100per">
                        <div className="searchbox-content-item-group-block">
                          <div className="searchbox-content-item-group-block-child small">
                            <div className="searchbox-content-item-group-block-child-item fx-1">
                              {/* <p className="mb-4 fz-12">チェックイン</p> */}
                              {/* <p className="mb-4 fz-12">入住日期</p> */}
                              <p className="mb-4 fz-12">{t("PlanSelectTop.チェックイン")}</p>
                              <div className="w-165-md">
                                <div className="form-date-seal">
                                  <div className="form-input-date-icon-wrap">
                                    <DatePicker
                                      dateFormat="yyyy/MM/dd"
                                      // locale='ja'
                                      locale={i18n.language === LANGUAGES.JA ? DATEPICKER_LOCALES.JA : DATEPICKER_LOCALES.TW}
                                      selected={new Date(checkIn)}
                                      value={dateFormat(checkIn, 'YYYY/MM/DD')}
                                      className="form-input-date"
                                      onChange={changeCheckIn}
                                      minDate={new Date(minDate)}
                                      maxDate={new Date(maxDate)}
                                      customInput={<ForceReadOnlyInput />}
                                      disabled={loading}
                                      calendarStartDay={0}
                                    />
                                    <p className="form-date-seal-label"> {dateFormat(checkIn, "YYYY/MM/DD")}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
    
                            <div className="searchbox-content-item-group-block-child-item fx-1">
                              {/* <p className="mb-4 fz-12">チェックアウト</p> */}
                              {/* <p className="mb-4 fz-12">退房日期</p> */}
                              <p className="mb-4 fz-12">{t("PlanSelectTop.チェックアウト")}</p>
                              <div className="w-165-md">
                                <div className="form-date-seal">
                                  <div className="form-input-date-icon-wrap">
                                    <DatePicker
                                      dateFormat="yyyy/MM/dd"
                                      // locale='ja'
                                      locale={i18n.language === LANGUAGES.JA ? DATEPICKER_LOCALES.JA : DATEPICKER_LOCALES.TW}
                                      selected={new Date(checkOut)}
                                      value={dateFormat(checkOut, 'YYYY/MM/DD')}
                                      className="form-input-date"
                                      onChange={changeCheckOut}
                                      minDate={new Date(minDate)}
                                      maxDate={new Date(maxDate)}
                                      customInput={<ForceReadOnlyInput />}
                                      disabled={loading}
                                      calendarStartDay={0}
                                    />
                                    <p className="form-date-seal-label"> {dateFormat(checkOut, "YYYY/MM/DD")}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isDateError && (
                    <>
                      <div className="box-alert-red-medium icon-alert">
                        {/* <p className="c-red fw-b mb-8">設定済みの宿泊日程が含まれているため選択できません。</p> */}
                        {/* <p className="c-red fw-b mb-8">因所設定的住宿日程已含在裡面，請恕無法選擇。</p> */}
                        <p className="c-red fw-b mb-8">{t("StaySelectModal.設定済みの宿泊日程が含まれているため選択できません。")}</p>
                        {/* <p className="c-navy fw-b fz-13 fz-14-md mb-4 mb-2-md">設定済みの宿泊日程</p> */}
                        {/* <p className="c-navy fw-b fz-13 fz-14-md mb-4 mb-2-md">已設定的住宿日程</p> */}
                        <p className="c-navy fw-b fz-13 fz-14-md mb-4 mb-2-md">{t("StaySelectModal.設定済みの宿泊日程")}</p>
                        <p className="fz-13 fz-14-md c-navy">
                          {planSelectFormData.hotel.map((hotelData, hIndex) => (
                            <Fragment key={hIndex}>
                              {toCircled(hIndex + 1)} {dateFormat(hotelData.checkIn, "YYYY年MM月DD日")} 〜 {dateFormat(hotelData.checkOut, "YYYY年MM月DD日")}
                              <br/>
                            </Fragment>
                          ))}
                        </p>
                      </div>
                    </>
                  )}
                </div>

                {/* 検索フォーム(ホテル選択) */}
                {defaultStep === 1 && areaSelectOption && areaSelectOption.length > 0 ?
                  <div className="card small">
                    <div className="animation-fade active">
                      <div className="serchbox-stay">
                        {/* <p className="fz-16 fz-15-md mb-16 mb-0-md fw-b ta-c pr-20-md w-180-md">条件で絞り込む</p> */}
                        {/* <p className="fz-16 fz-15-md mb-16 mb-0-md fw-b ta-c pr-20-md w-180-md">增加搜尋條件</p> */}
                        <p className="fz-16 fz-15-md mb-16 mb-0-md fw-b ta-c pr-20-md w-180-md">{t("StaySearchbox.条件で絞り込む")}</p>
                        <dl className="d-f-md fw-w bl-gray-md pl-30-md">
                          <div className="d-f ai-c mt-16 mt-0-md w-100per">
                            {/* <dt className="c-navy fz-13 fz-14-md fw-b mr-16 fx-sh">地區</dt> */}
                            <dt className="c-navy fz-13 fz-14-md fw-b mr-16 fx-sh">{t("StaySearchbox.エリア")}</dt>
                            <dd className="w-100per w-200-md">
                              <List component="div" className="form-select_list">
                                <ListItem
                                  button
                                  id="lock-button"
                                  aria-haspopup="listbox"
                                  aria-controls="lock-menu"
                                  // aria-label="when device is locked"
                                  aria-expanded={StaySelectOpen ? 'true' : undefined}
                                  className="form-select_list-inner"
                                  onClick={handleClickListItem}
                                >
                                  <ListItemText
                                    // primary="When device is locked"
                                    secondary={t("HotelArea."+areaSelectOption[selectedIndex])}
                                    className="form-select_list-selected_text text_medium"
                                  />
                                </ListItem>
                              </List>
                              <Menu
                                id="lock-menu"
                                anchorEl={anchorEl}
                                open={StaySelectOpen}
                                className="form-select_list-options"
                                onClose={StaySlectClose}
                                MenuListProps={{
                                'aria-labelledby': 'lock-button',
                                role: 'listbox',
                                }}
                              >
                                {areaSelectOption.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    // disabled={index === 0}
                                    className="form-select_list-options-item"
                                    selected={index === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                  >
                                    {t("HotelArea."+option)}
                                  </MenuItem>
                                ))}
                              </Menu>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                : ""}

                {defaultStep === 2 && (roomTypeFilter?.length > 0 || mealFilter?.length > 0) ?
                  <div className="card small">
                    <div className="animation-fade active">
                      <div className="serchbox-stay">
                        {/* <p className="fz-16 fz-15-md mb-16 mb-0-md fw-b ta-c pr-10-md w-150-md">条件で絞り込む</p> */}
                        <p className="fz-16 fz-15-md mb-16 mb-0-md fw-b ta-c pr-10-md w-150-md">{t("StaySearchbox.条件で絞り込む")}</p>
                        <dl className="d-f-md fw-w bl-gray-md pl-30-md">
                          {roomTypeFilter && roomTypeFilter.length > 0 ?
                            <div className="d-f ai-c mb-10 mb-0-md">
                              {/* <dt className="c-navy fz-13 fz-14-md fw-b mr-16 fx-sh">房型</dt> */}
                              <dt className="c-navy fz-13 fz-14-md fw-b mr-16 fx-sh">{t("SearchCounter.お部屋")}</dt>
                              <dd className="fx-1">
                                <ToggleButtonGroup
                                  value={roomTypeFilterState}
                                  onChange={handleRoomTypeFilter}
                                  aria-label="text formatting"
                                  className="box-toggle_button w-a"
                                >
                                  {roomTypeFilter.map((row,index) => (
                                    <ToggleButton key={"roomToggle_"+index} value={row.value} aria-label={row.value} className="box-toggle_button-item">
                                      {row.label}
                                    </ToggleButton>
                                  ))}
                                </ToggleButtonGroup>
                              </dd>
                            </div>
                          : ""}
                          
                          {mealFilter && mealFilter.length > 0 ?
                            <div className="d-f ai-c pl-40-md">
                              {/* <dt className="c-navy fz-13 fz-14-md fw-b mr-16 fx-sh">餐食</dt> */}
                              <dt className="c-navy fz-13 fz-14-md fw-b mr-16 fx-sh">{t("StaySearchbox.お食事")}</dt>
                              <dd className="fx-1">
                                <ToggleButtonGroup
                                  value={mealFilterState}
                                  onChange={handleMealFilter}
                                  aria-label="text formatting"
                                  className="box-toggle_button w-a"
                                >
                                  {mealFilter.map((row,index) => (
                                    <ToggleButton key={"eats_"+index} value={row.value} aria-label={row.value} className="box-toggle_button-item">
                                      {row.label}
                                    </ToggleButton>
                                  ))}
                                </ToggleButtonGroup>
                              </dd>
                            </div>
                          : ""}

                        </dl>
                      </div>
                    </div>
                  </div>
                : ""}
                
                {/* 検索結果 */}
                <div className={(defaultStep === 1? " animation-fade active":" animation-fade")}>
                    <div className="d-f jc-sb mt-32 mt-40-md mb-16">
                        {/* <div className="fz-12 fz-14-md fw-b">検索結果<span className="c-red fz-20 fz-22-md ml-5 mr-5">{searchTotal}</span>件</div> */}
                        {/* <div className="fz-12 fz-14-md fw-b">搜尋結果<span className="c-red fz-20 fz-22-md ml-5 mr-5">{searchTotal}</span>件</div> */}
                        <div className="fz-12 fz-14-md fw-b">{t("StaySearchbox.検索結果")}<span className="c-red fz-20 fz-22-md ml-5 mr-5">{searchTotal}</span>件</div>
                        <div className="box-sort small">
                            <ul className="box-sort-list">
                              {planSelectFormData.planData?.sellerIndo.hotelSettings.target === "ホテル指定" &&
                                <li key={"result_recommend"} className="box-sort-list-item">
                                  <button 
                                    className={selectedHotelSort !== "recommend" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                    onClick={() => sortHotels("sortIndex", "recommend")}>{t("StaySearchbox.おすすめ順")}</button>
                                </li>
                              }
                            <li key={"result_cheapest"} className="box-sort-list-item">
                              <button 
                                className={selectedHotelSort !== "asc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                onClick={() => sortHotels("min_price", "asc")}>{t("StaySearchbox.価格が安い順")}</button>
                            </li>
                            <li key={"result_expensive"} className="box-sort-list-item">
                              <button 
                                className={selectedHotelSort !== "desc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                onClick={() => sortHotels("min_price", "desc")}>{t("StaySearchbox.価格が高い順")}</button>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className={(defaultStep === 2? " animation-fade active":" animation-fade")}>
                    <div className="d-f jc-sb ai-c mt-32 mt-40-md mb-16">
                      {planSelectFormData.isHotels && (
                        <>
                          <div className="fz-12 fz-14-md fw-b">
                            <button className="button-clear-blue" onClick={()=> onPageHandle(defaultStep-1)}>
                              <i className="icon-left-arrow-light_blue mr-5"></i>{t("StaySearchbox.宿泊施設の選択に戻る")}
                            </button>
                          </div>
                        </>
                      )}
                        <div className="box-sort small">
                            <ul className="box-sort-list">
                                <li className="box-sort-list-item">
                                  <button 
                                    className={selectedRoomSort !== "asc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                    onClick={() => sortRoomPlan("asc")}>{t("StaySearchbox.価格が安い順")}</button>
                                </li>
                                <li className="box-sort-list-item">
                                  <button 
                                    className={selectedRoomSort !== "desc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                    onClick={() => sortRoomPlan("desc")}>{t("StaySearchbox.価格が高い順")}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
              
                {/* 表示コンテンツ */}

                { loading?
                  <>
                    <SearchLoading isLoading={loading} />
                  </>
                  :
                  <>
                    <div className={(defaultStep === 1? " animation-fade active":" animation-fade")}>
                      <div className="box-plan-bt_gray">
                        <div className="box-plan-bt_gray-list">
                          {hotels?.map((hotel, hIndex) => (
                            <div key={hIndex} className="box-plan-bt_gray-list-item">
                              <div className="box-stay">
                                <div className="box-stay-item">
                                  <div className="box-stay-item-thumbnail large">
                                    <img
                                      src={hotel.image_l_url}
                                      alt=""
                                    />
                                  </div>
                                  <div className="box-stay-item-content">
                                    <div className="box-stay-item-content-area">
                                      <div className="list-stay-label">
                                        {hotel.sub_area_name !== undefined ? (
                                          <ul className="list-stay-label-list">
                                            <li className="list-stay-label-list-item">{hotel.sub_area_name}</li>
                                          </ul>
                                        ) : (<></>)}
                                      </div>
                                    </div>
                                    <div className="box-stay-item-content-heading">
                                      {hotel.hotel_name}
                                    </div>
                                    <div className="mb-20-md">
                                      {/* <button onClick={() => handleHotelDetail(hotel)} className="button-border-small-blue w-100per w-a-md">ホテルの詳細を見る</button> */}
                                      <button 
                                        onClick={() => handleHotelDetail(hotel)} 
                                        className="button-border-small-blue w-100per w-a-md"
                                      >
                                        {/* 查看飯店介紹 */}
                                        {t("StaySearchResult.ホテルの詳細を見る")}
                                      </button>
                                    </div>

                                    {/* 以下PCのみ */}
                                    <div className="box-stay-item-content-label d-n d-b-md">
                                      <div className="list-stay-label">
                                        <ul className="list-stay-label-list mb-20-md">
                                          {hotel.hotel_type_list?.map((hotelType, htIndex) => (
                                            <li key={htIndex} className="list-stay-label-list-item">{getHotelTypeLabel(hotelType.hotel_type_code, i18n.language)}</li>
                                          ))}
                                        </ul>
                                      </div>
                                      <div className="d-n d-b-md">
                                        <PartsHotelTagList
                                          hotel_facility_list={hotel.hotel_facility_list}
                                          ulClass="parts-hotel_tag-list d-f fw-w"
                                          languageCode={i18n.language}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* sp時 */}
                                  <div className="box-stay-item-content-sp mt-24 mb-16">
                                    <div className="d-n-md">
                                      <div className="list-stay-label">
                                        <ul className="list-stay-label-list mb-20-md">
                                          {hotel.hotel_type_list?.map((hotelType, htIndex) => (
                                            <li key={htIndex} className="list-stay-label-list-item">{getHotelTypeLabel(hotelType.hotel_type_code, i18n.language)}</li>
                                          ))}
                                        </ul>
                                      </div>
                                      <div className="mt-16">
                                        <PartsHotelTagList
                                          hotel_facility_list={hotel.hotel_facility_list}
                                          ulClass="parts-hotel_tag-list d-f fw-w"
                                          languageCode={i18n.language}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="box-stay-item-detail">
                                    <div className="w-100per">
                                      <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                        <p className="mb-5-md fz-13 fw-b ta-c-md">
                                          {/* 追加費用<br /> */}
                                          {t("HotelPlanSearchResult.追加料金")}<br />
                                          {/* <span className="fz-10 fz-11-md">（１人あたり）</span> */}
                                          {/* <span className="fz-10 fz-11-md">（每一人）</span> */}
                                          <span className="fz-10 fz-11-md">（{t("HotelPlanSearchResult.１人あたり")}）</span>
                                        </p>
                                        <p className="mb-10-md c-red fz-24 fw-b ta-c-md">
                                          {diffUpdateHotelPrice(planSelectFormData, {
                                            isUpdate: isUpdate,
                                            step: 'hotel',
                                            updateHotelIndex: updateHotelIndex,
                                            updatePlan: undefined,
                                            updateHotel: hotel,
                                            checkIn: checkIn,
                                            checkOut: checkOut
                                          })}<span className="fz-12">{t("Common.円")}〜</span>
                                        </p>
                                      </div>
                                      <p className="mb-10-md">
                                        <button
                                          onClick={()=> onPageHandlePlanDetail(hotel.hotel_code)}
                                          className="button-medium-light_blue button-variable sp-large w-100per"
                                        >
                                          {/* この宿泊施設を<br className="d-n d-b-md" />
                                          選択する */}
                                          {/* 選擇此住宿設施 */}
                                          {
                                            i18n.language === LANGUAGES.JA ? (
                                              <>この宿泊施設を<br className="d-n d-b-md" />選択する</>
                                            ) : (
                                              <>選擇此住宿設施</>
                                            )
                                          }
                                        </button>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          ))}

                        </div>
                      </div>
                    </div>

                    <div className={(defaultStep === 2? " animation-fade active":" animation-fade")}>
                      <div>
                          <>
                            {hotelDetail !== undefined ?
                              <>
                                <div>
                                  <div className="box-heading-navy">{hotelDetail?.hotel_name}</div>
                                  {/* 現在選択されているプラン */}

                                  {planExists === true ? 
                                  hotelDetail?.roomData?.map((data, rindex) => (
                                    data?.planList !== undefined && data.planList.length > 0 ?
                                    <Fragment key={rindex}>
                                      <div className={roomBaxClass(rindex)}>
                                        <div className="box-stay">
                                          <div className="box-stay-item">
                                            <div className="box-stay-item-thumbnail large">
                                              {data?.detail.room_info.image_list !== undefined?
                                                <img
                                                  src={data?.detail.room_info.image_list[0]?.image_url}
                                                  alt=""
                                                />
                                                :
                                                <img
                                                  src={hotelDetail?.image_l_url}
                                                  alt=""
                                                />
                                              }
                                            </div>
                                            <div className="box-stay-item-content">
                                              <div className="box-stay-item-content-heading">
                                                {data.detail?.room_info.room_name}
                                              </div>
                                              <div className="mt-5 mb-16-md">
                                                {/* <button type="button" onClick={() => openRoomDetailModal(data)} className="button-border-small-blue w-100per w-a-md">客室詳細を見る</button> */}
                                                <button 
                                                  type="button" 
                                                  onClick={() => openRoomDetailModal(data)} 
                                                  className="button-border-small-blue w-100per w-a-md"
                                                >
                                                  {/* 客房簡介 */}
                                                  {t("HotelPlanSearchResult.客室詳細を見る")}
                                                </button>
                                              </div>

                                              {/* 以下PCのみ */}
                                              <div className="box-stay-item-content-label d-n d-b-md">
                                                <div className="list-stay-label">
                                                  <ul className="list-stay-label-list">
                                                    {data.detail.room_info?.room_feature_list?.map((room_feature, rfIndex) => (
                                                      <li key={rfIndex} className="list-stay-label-list-item">{room_feature.name}</li>
                                                    ))}
                                                  </ul>
                                                </div>
                                                <p className="fz-13">
                                                  {/* 【ルームタイプ】{getRoomTypeLabel(data.detail.room_info.room_type_code)} */}
                                                  【{t("Hotel.ルームタイプ")}】{getRoomTypeLabel(data.detail.room_info.room_type_code, i18n.language)}
                                                  {/* {data.detail.room_info.room_size ? <>【広さ】{data.detail.room_info.room_size}㎡</> : ""} */}
                                                  {data.detail.room_info.room_size ? <>【{t("Hotel.広さ")}】{data.detail.room_info.room_size}㎡</> : ""}
                                                  {data.detail.room_info.bedding_list?
                                                    <>
                                                      {/* 【ベッドサイズ】 */}
                                                      【{t("Hotel.ベッドサイズ")}】
                                                      {data.detail.room_info.bedding_list?.map((bedding, bIndex)  => (
                                                        <Fragment key={bIndex}>
                                                          {bedding.name}
                                                        </Fragment>
                                                      ))}
                                                    </>  :<></>
                                                  }

                                                </p>
                                              </div>
                                            </div>

                                            {/* sp時 */}
                                            <div className="box-stay-item-content-sp mt-16 mt-0-md mb-16 mb-0-md w-100per">
                                              <div className="d-n-md">
                                                <div className="list-stay-label">
                                                  <ul className="list-stay-label-list mb-20-md">
                                                    {data.detail.room_info?.room_feature_list?.map((room_feature, rfIndex) => (
                                                      <li key={rfIndex} className="list-stay-label-list-item">{room_feature.name}</li>
                                                    ))}
                                                  </ul>
                                                </div>
                                                <p className="fz-13">
                                                  {/* 【ルームタイプ】{getRoomTypeLabel(data.detail.room_info.room_type_code)} */}
                                                  【{t("Hotel.ルームタイプ")}】{getRoomTypeLabel(data.detail.room_info.room_type_code, i18n.language)}
                                                  {/* {data.detail.room_info.room_size ? <>【広さ】{data.detail.room_info.room_size}㎡</> : ""} */}
                                                  {data.detail.room_info.room_size ? <>【{t("Hotel.広さ")}】{data.detail.room_info.room_size}㎡</> : ""}
                                                  {data.detail.room_info.bedding_list?
                                                    <>
                                                      {/* 【ベッドサイズ】 */}
                                                      【{t("Hotel.ベッドサイズ")}】
                                                      {data.detail.room_info.bedding_list?.map((bedding, bIndex)  => (
                                                        <Fragment key={bIndex}>
                                                          {bedding.name}
                                                        </Fragment>
                                                      ))}
                                                    </>  :<></>}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="box-light_navy small radius-bottom">
                                        {/* アコーディオンに隠れない一個目の内藤 */}
                                        {data.first_plan !== undefined ? <>
                                          <div className="box-stay-list">
                                            <div className="box-stay-list-item">
                                              <div className="box-white small-pc mb-8 mb-16-md">
                                                <div className="box-stay">
                                                  <div className="box-stay-item">
                                                    <div className="box-stay-item-thumbnail middle">
                                                      {data.first_plan.room_plan_detail.plan_image_list !== undefined?
                                                        <img
                                                          src={data.first_plan.room_plan_detail.plan_image_list[0].plan_image_url}
                                                          alt=""
                                                        />
                                                        :
                                                        <img
                                                          src={roomData?.detail.room_info.image_list[0].image_url}
                                                          alt=""
                                                        />
                                                      }
                                                    </div>
                                                    <div className="box-stay-item-content">

                                                      <div className="box-stay-item-content-heading">
                                                        {data.first_plan.room_plan_detail.plan_name}
                                                      </div>
                                                      <div className="mt-16 mt-20-md mb-16 mb-20-md">
                                                        {/* <button onClick={() => onClickPlandetailModal(data.first_plan, data)} className="button-border-small-blue w-100per w-a-md">プラン詳細を見る</button> */}
                                                        <button 
                                                          onClick={() => onClickPlandetailModal(data.first_plan, data)} 
                                                          className="button-border-small-blue w-100per w-a-md"
                                                        >
                                                          {/* 查看住宿方案 */}
                                                          {t("HotelPlanSearchResult.プラン詳細を見る")}
                                                        </button>
                                                      </div>
                                                      <div className="box-stay-item-content-label">
                                                        <ul className="box-stay-item-content-label-list light-green">
                                                          {data.first_plan.room_plan_detail.meal_breakfast === "0" && data.first_plan.room_plan_detail.meal_lunch === "0" && data.first_plan.room_plan_detail.meal_dinner === "0" && (
                                                            <li className="box-stay-item-content-label-list-item">
                                                              {/* 食事なし */}
                                                              {/* 無附餐 */}
                                                              {t("Hotel.食事なし")}
                                                            </li>
                                                          )}
                                                          {data.first_plan.room_plan_detail.meal_breakfast === "1" && (
                                                            <li className="box-stay-item-content-label-list-item">
                                                              {/* 朝食 */}
                                                              {/* 早餐 */}
                                                              {t("Hotel.朝食")}
                                                            </li>
                                                          )}
                                                          {data.first_plan.room_plan_detail.meal_lunch === "1" && (
                                                            <li className="box-stay-item-content-label-list-item">
                                                              {/* 昼食 */}
                                                              {/* 午餐 */}
                                                              {t("Hotel.昼食")}
                                                            </li>
                                                          )}
                                                          {data.first_plan.room_plan_detail.meal_dinner === "1" && (
                                                            <li className="box-stay-item-content-label-list-item">
                                                              {/* 夕食 */}
                                                              {/* 晚餐 */}
                                                              {t("Hotel.夕食")}
                                                            </li>
                                                          )}
                                                        </ul>
                                                      </div>

                                                      <p className="fz-13">
                                                        {/* チェックイン：{data.detail.room_plan_detail.check_in_start}　チェックアウト：{data.detail.room_plan_detail.check_out} */}
                                                        {/* 辦理入住時間：{data.detail.room_plan_detail.check_in_start}　辦理退房時間：{data.detail.room_plan_detail.check_out} */}
                                                        {t("HotelPlanSearchResult.チェックイン")}：{data.detail.room_plan_detail.check_in_start}　{t("HotelPlanSearchResult.チェックアウト")}：{data.detail.room_plan_detail.check_out}
                                                      </p>
                                                    </div>


                                                    <div className="box-stay-item-detail mt-10 mt-0-md">
                                                      <div className="w-100per">
                                                        <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                                          <p className="mb-5-md fz-13 fw-b ta-c-md">
                                                            {t("HotelPlanSearchResult.追加料金")}<br />
                                                            {/* <span className="fz-10 fz-11-md">（１人あたり）</span> */}
                                                            <span className="fz-10 fz-11-md">（{t("HotelPlanSearchResult.１人あたり")}）</span>
                                                          </p>
                                                          <p className="mb-10-md c-red fz-24 fw-b ta-c-md">
                                                            {diffUpdateHotelPrice(planSelectFormData, {
                                                              isUpdate: isUpdate,
                                                              step: 'plan',
                                                              updateHotelIndex: updateHotelIndex,
                                                              updatePlan: data.first_plan,
                                                              updateHotel: undefined,
                                                              checkIn: checkIn,
                                                              checkOut: checkOut
                                                            })}<span className="fz-12">日圓</span>
                                                          </p>
                                                        </div>
                                                        <p className="mb-10-md">
                                                          <button
                                                            onClick={() => callbackUpdateSelectHotel(data.first_plan, data)}
                                                            className="button-medium-light_blue button-variable sp-large w-100per"
                                                          >
                                                            {/* このプランを<br className="d-n d-b-md" />
                                                            選択する */}
                                                            {/* 選擇此住宿方案 */}
                                                            {
                                                              i18n.language === LANGUAGES.JA ? (
                                                                <>このプランを<br className="d-n d-b-md" />選択する</>
                                                              ) : (
                                                                <>選擇此住宿方案</>
                                                              )
                                                            }
                                                          </button>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </>:<></>}
                                        {/* アコーディオンに隠れるコンテンツ */}
                                        {data.planList !== undefined && data.planList?.length > 1 ?
                                          <>
                                            <div>
                                              <div className="accordion-stay">
                                                <div className={"accordion-stay-detail" + (!StayAccordionStateList[rindex] ? " ":" active")}>
                                                  <div className="box-stay-list mb-8 mb-16-md">
                                                    {data.planList?.map((plan_list_data,pIndex) => (
                                                      <Fragment key={pIndex}>
                                                        {pIndex !== 0 ?
                                                          <>
                                                            <div className="box-stay-list-item">
                                                              <div className="box-white small-pc">
                                                                <div className="box-stay">
                                                                  <div className="box-stay-item">
                                                                    <div className="box-stay-item-thumbnail sp_small-pc_large">
                                                                      {plan_list_data.room_plan_detail.plan_image_list !== undefined?
                                                                        <img
                                                                          src={plan_list_data.room_plan_detail.plan_image_list[0].plan_image_url}
                                                                          alt=""
                                                                        />
                                                                        :
                                                                        <img
                                                                          src={roomData?.detail.room_info.image_list[0].image_url}
                                                                          alt=""
                                                                        />
                                                                      }
                                                                    </div>
                                                                    <div className="box-stay-item-content">

                                                                      <div className="box-stay-item-content-heading">
                                                                        {plan_list_data.room_plan_detail.plan_name}
                                                                      </div>
                                                                      <div className="mt-16 mt-20-md mb-16 mb-20-md">
                                                                        {/* <button onClick={() => onClickPlandetailModal(plan_list_data, data)} className="button-border-small-blue w-100per w-a-md">プラン詳細を見る</button> */}
                                                                        <button 
                                                                          onClick={() => onClickPlandetailModal(plan_list_data, data)} 
                                                                          className="button-border-small-blue w-100per w-a-md"
                                                                        >
                                                                          {t("HotelPlanSearchResult.プラン詳細を見る")}
                                                                        </button>
                                                                      </div>
                                                                      <div className="box-stay-item-content-label">
                                                                        <ul className="box-stay-item-content-label-list light-green">
                                                                          {plan_list_data.room_plan_detail.meal_breakfast === "0" && plan_list_data.room_plan_detail.meal_lunch === "0" && plan_list_data.room_plan_detail.meal_dinner === "0" && (
                                                                            <li className="box-stay-item-content-label-list-item">
                                                                              {t("Hotel.食事なし")}
                                                                            </li>
                                                                          )}
                                                                          {plan_list_data.room_plan_detail.meal_breakfast === "1" && (
                                                                            <li className="box-stay-item-content-label-list-item">
                                                                              {t("Hotel.朝食")}
                                                                            </li>
                                                                          )}
                                                                          {plan_list_data.room_plan_detail.meal_lunch === "1" && (
                                                                            <li className="box-stay-item-content-label-list-item">
                                                                              {t("Hotel.昼食")}
                                                                            </li>
                                                                          )}
                                                                          {plan_list_data.room_plan_detail.meal_dinner === "1" && (
                                                                            <li className="box-stay-item-content-label-list-item">
                                                                              {t("Hotel.夕食")}
                                                                            </li>
                                                                          )}
                                                                        </ul>
                                                                      </div>

                                                                      <p className="fz-13">
                                                                        {/* チェックイン：{data.detail.room_plan_detail.check_in_start}　チェックアウト：{data.detail.room_plan_detail.check_out} */}
                                                                        {t("HotelPlanSearchResult.チェックイン")}：{data.detail.room_plan_detail.check_in_start}　{t("HotelPlanSearchResult.チェックアウト")}：{data.detail.room_plan_detail.check_out}
                                                                      </p>
                                                                    </div>

                                                                    <div className="box-stay-item-detail mt-10 mt-0-md">
                                                                      <div className="w-100per">
                                                                        <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                                                          <p className="mb-5-md fz-13 fw-b ta-c-md">
                                                                            {t("HotelPlanSearchResult.追加料金")}<br />
                                                                            {/* <span className="fz-10 fz-11-md">（１人あたり）</span> */}
                                                                            <span className="fz-10 fz-11-md">（{t("HotelPlanSearchResult.１人あたり")}）</span>
                                                                          </p>
                                                                          <p className="mb-10-md c-red fz-24 fw-b ta-c-md">
                                                                            {diffUpdateHotelPrice(planSelectFormData, {
                                                                              isUpdate: isUpdate,
                                                                              step: 'plan',
                                                                              updateHotelIndex: updateHotelIndex,
                                                                              updatePlan: plan_list_data,
                                                                              updateHotel: undefined,
                                                                              checkIn: checkIn,
                                                                              checkOut: checkOut
                                                                            })}<span className="fz-12">{t("Common.円")}</span>
                                                                          </p>
                                                                        </div>
                                                                        <p className="mb-10-md">
                                                                          <button
                                                                            onClick={() => callbackUpdateSelectHotel(plan_list_data, data)}
                                                                            className="button-medium-light_blue button-variable sp-large w-100per"
                                                                          >
                                                                            {/* このプランを<br className="d-n d-b-md" />
                                                                            選択する */}
                                                                            {/* 選擇此住宿方案 */}
                                                                            {
                                                                              i18n.language === LANGUAGES.JA ? (
                                                                                <>このプランを<br className="d-n d-b-md" />選択する</>
                                                                              ) : (
                                                                                <>選擇此住宿方案</>
                                                                              )
                                                                            }
                                                                          </button>
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>:<></>
                                                        }
                                                      </Fragment>
                                                    ))}

                                                  </div>
                                                </div>

                                                {/* アコーディオンの開閉ボタン */}
                                                <div className="accordion-stay-heading">
                                                  <button onClick={() => handleStayAccordionList(rindex)} className="accordion-stay-button button-clear">
                                                    {
                                                      (() => {
                                                        if (!StayAccordionStateList[rindex]) {
                                                          return (<span><span className="pr-24">{t("HotelPlanSearchResult.宿泊プランをすべて見る")}</span><span className="accordion-stay-icon"></span></span>);
                                                        } else {
                                                          return (<span><span className="pr-24">{t("HotelPlanSearchResult.閉じる")}</span><span className="accordion-stay-icon active"></span></span>);
                                                        }
                                                      })()
                                                    }
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                          :<></>}

                                      </div>
                                    </Fragment>
                                  : ""))
                                  :<>
                                    {/* プランが存在しない場合 */}
                                    <div className="box-white radius-none small-pc">
                                      <div className="box-stay">
                                        <div className="box-stay-item">
                                          <div className="box-stay-item-content">
                                            <section className="pt-20 pb-20">
                                              <div className="wrap-content-900">
                                                <p className="mb-10 ta-c">
                                                  <img src={require("../../img/share/icon/icon_searchzero.svg").default} alt="" className="d-ib" />
                                                </p>
                                                {/* <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">選択可能なプランが存在しません</p> */}
                                                <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">{t("HotelPlanSearchResult.選択可能なプランが存在しません")}</p>
                                              </div>
                                            </section>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>}
                                </div></>
                              :
                              <>
                                <SearchNotFound></SearchNotFound>
                              </>}
                          </>
                      </div>

                      {planSelectFormData.isHotels && (
                        <>
                          <div className="mt-32 ta-c">
                            <button onClick={()=> onPageHandle(defaultStep-1)} className="button-border-small-blue medium w-100per w-a-md ml-a-md mr-a-md">
                              {/* <i className="icon-left-arrow-light_blue mr-5"></i>返回上一頁  選擇住宿設施 */}
                              <i className="icon-left-arrow-light_blue mr-5"></i>{t("StaySearchbox.宿泊施設の選択に戻る")}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

        {/* ここに作成したモーダルを追加する */}
        <HotelHandModal 
          isShow={a} 
          callback={handleA} 
          isUpdate={isUpdate} 
          updateIndex={updateHotelIndex} 
          hotelList={hotelList} 
          planSelectFormData={planSelectFormData} 
          selectHotel={onPageModalHandlePlanDetail}
          // languageCode={languageCode}
        />
        {/*<PlanDetailModal isShow={b} updateSelectHotel={callbackUpdateSelectHotel} callback={handleB} planData={undefined} roomData={undefined} />*/}
        {/*<RoomDetailModal isShow={c} callback={handleC} roomData={undefined} />*/}
        {/* モーダル：プラン詳細 */}
        <PlanDetailModal 
          planSelectFormData={planSelectFormData} 
          isUpdate={isUpdate} 
          updateIndex={updateHotelIndex} 
          isShow={PlanDetailModalState} 
          updateSelectHotel={callbackUpdateSelectHotel} 
          callback={handlPlandetailModal} 
          planData={planData} 
          roomData={roomData} 
        />
  
        {/* モーダル：客室詳細 */}
        <RoomDetailModal
          isShow={roomDetailModalState} 
          roomData={roomData} 
          callback={handleRoomDetailModal}
          // languageCode={languageCode}
        />
    </>
  );
};

export default StaySelectModal;
