import {
  carRentalAnshinOptionType,
  carRentalChildSeatOptionNamesType,
  carRentalChildSeatOptionType
} from "../types/carRental";

/**
 * 一台あたりに設定できるチャイルドシートの数
 */
export const perSheetMaxNum = 3;

export const carRentalOfficeCodes = [
  {
    label: '臨空豊崎営業所(那覇空港）',
    code: "toyosaki",
  },
  {
    label: 'DFS営業所（おもろまちモノレール駅）',
    code: "dfs",
  },
  // @memo 非表示（店舗としては存在しているが国内個人旅行客は基本受けていないため）
  // {
  //   label: '赤嶺店',
  //   code: "akamine-walk-in",
  // },
  {
    label: '新石垣空港',
    code: "ishigaki",
  },
  // {
  //   label: '宮古空港',
  //   code: "miyako",
  // },
  {
    label: '下地島空港',
    code: "shimoji",
  },
]

export const carRentalBorrowingTimeOptions = [
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
];

export const carRentalReturnTimeOptions = [
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
];

export const carRentalAccessType = [
  {
    label: '空港送迎',
    code: "airport",
  },
  {
    label: '直接来店',
    code: "shop",
  }
]

export const carRentalFlights = [
  {
    label: 'ANA（全日空）',
    code: "ANA",
  },
  {
    label: 'JAL（日本航空）',
    code: "JAL",
  },
  {
    label: 'JTA（日本トランスオーシャン航空）',
    code: "JTA",
  },
  {
    label: 'RAC（琉球エアコミューター）',
    code: "RAC",
  },
  {
    label: 'SNA（ソラシドエア）-日本',
    code: "SNA",
  },
  {
    label: 'ADO（エア・ドゥ）-日本',
    code: "ADO",
  },
  {
    label: 'SKY（スカイマーク）-日本',
    code: "SKY",
  },
  {
    label: 'GK（jetstar）-日本',
    code: "GK",
  },
  {
    label: 'MM（Peach）-日本',
    code: "MM",
  },
  {
    label: 'JW（Vanilla）-日本',
    code: "JW",
  },
  {
    label: 'BR（エバー航空）-台湾',
    code: "BR",
  },
  {
    label: 'GE（復興航空）-台湾',
    code: "GE",
  },
  {
    label: 'CI（チャイナエアライン）-台湾',
    code: "CI",
  },
  {
    label: 'IT（タイガー・エアウェイズ）-台湾',
    code: "IT",
  },
  {
    label: 'AE（マンダリン航空）-台湾',
    code: "AE",
  },
  {
    label: 'HX（香港航空）-香港',
    code: "HX",
  },
  {
    label: 'KA（ドラゴン航空）-香港',
    code: "KA",
  },
  {
    label: 'UO（香港エクスプレス）-香港',
    code: "UO",
  },
  {
    label: 'MU（中國東方航空）-中国',
    code: "MU",
  },
  {
    label: 'HO（吉祥航空）-上海',
    code: "HO",
  },
  {
    label: 'CA（中國国際航空）-中国',
    code: "CA",
  }
]

export const carRentalChildSeatOptionNames: carRentalChildSeatOptionNamesType[] = [
  {
    ja: "ベビーシート",
    tw: "嬰兒座椅(新生兒-1歲)",
  },
  {
    ja: "チャイルドシート",
    tw: "幼兒座椅（6個月-4歲）",
  },
  {
    ja: "ジュニアシート",
    tw: "兒童用座椅 （4歲-6歲）",
  }
];

export const carRentalChildSeatOptions: carRentalChildSeatOptionType = {
  ja: [
    {
      img: require("../img/share/rentacar/rentacar_img_Seat_01.png").default,
      title: "ベビーシート",
      heading: "乳幼児ベビーシート",
      target_age: "新生児から生後1歳位まで",
      weight: "10kg位まで",
      height: "75cm位まで",
      code: 'baby_seat',
    },
    {
      img: require("../img/share/rentacar/rentacar_img_Seat_02.png").default,
      title: "チャイルドシート",
      heading: "幼児用チャイルドシート",
      target_age: "生後6ヶ月から4歳位まで",
      weight: "18kg位まで",
      height: "100cm位まで",
      code: 'child_seat',
    },
    {
      img: require("../img/share/rentacar/rentacar_img_Seat_03.png").default,
      title: "ジュニアシート",
      heading: "学童用ジュニアシート",
      target_age: "4歳から6歳位まで",
      weight: "32kg位まで",
      height: "135cm位まで",
      code: 'junior_seat',
    },
  ],
  tw: [
    {
      img: require("../img/share/rentacar/rentacar_img_Seat_01.png").default,
      title: "嬰兒座椅(新生兒-1歲)",
      heading: "乳幼児ベビーシート",
      target_age: "新生児から生後1歳位まで",
      weight: "10kg位まで",
      height: "75cm位まで",
      code: 'baby_seat',
    },
    {
      img: require("../img/share/rentacar/rentacar_img_Seat_02.png").default,
      title: "幼兒座椅（6個月-4歲）",
      heading: "幼児用チャイルドシート",
      target_age: "生後6ヶ月から4歳位まで",
      weight: "18kg位まで",
      height: "100cm位まで",
      code: 'child_seat',
    },
    {
      img: require("../img/share/rentacar/rentacar_img_Seat_03.png").default,
      title: "兒童用座椅 （4歲-6歲）",
      heading: "学童用ジュニアシート",
      target_age: "4歳から6歳位まで",
      weight: "32kg位まで",
      height: "135cm位まで",
      code: 'junior_seat',
    },
  ]
}

export const carRentalAnshinOptions: carRentalAnshinOptionType = {
  ja: [
    {
      img: require("../img/share/rentacar/rentacar_img_anshin_basic.png").default,
      title: "ベーシック",
      heading: "安心パック",
      name: "安心パック",
      label: "安心パック/ベーシック",
      code: 'anshin',
    },
    {
      img: require("../img/share/rentacar/rentacar_img_anshin_premium.png").default,
      title: "プレミアム",
      heading: "安心パック",
      name: "プレミアム安心パック",
      label: "安心パック/プレミアム",
      code: 'anshin_premium',
    }
  ],
  tw: [
    {
      img: require("../img/share/rentacar/rentacar_img_anshin_basic.png").default,
      title: "ベーシック",
      heading: "安心パック",
      name: "安心保險",
      label: "安心パック/ベーシック",
      code: 'anshin',
    },
    {
      img: require("../img/share/rentacar/rentacar_img_anshin_premium.png").default,
      title: "プレミアム",
      heading: "安心パック",
      name: "豪華安心包",
      label: "安心パック/プレミアム",
      code: 'anshin_premium',
    }
  ],
}